import { Palette, TypographyVariantsOptions } from '@mui/material/styles'
import palette from './palette'
import { Global, css } from '@emotion/react'

// @TODO match these to member-forms-deprecated-app.css

const typographyOptions = (palette: Palette): TypographyVariantsOptions => ({
  fontFamily: "'ars-maquette-web', sans-serif, Helvetica, Arial, 'sans-serif'",
  h1: {
    color: palette.text.primary,
    fontWeight: 400,
    fontSize: '35px',
    letterSpacing: '-0.24px',
    lineHeight: '40px',
  },
  h2: {
    color: palette.text.primary,
    fontWeight: 400,
    fontSize: '29px',
    letterSpacing: '-0.24px',
    lineHeight: '32px',
  },
  h3: {
    color: palette.text.primary,
    fontWeight: 400,
    fontSize: '24px',
    letterSpacing: '-0.06px',
    lineHeight: '28px',
  },
  h4: {
    color: palette.text.primary,
    fontWeight: 400,
    fontSize: '20px',
    letterSpacing: '-0.06px',
    lineHeight: '24px',
  },
  h5: {
    color: palette.text.primary,
    fontWeight: 400,
    fontSize: '16px',
    letterSpacing: '-0.05px',
    lineHeight: '20px',
  },
  h6: {
    color: palette.text.primary,
    fontWeight: 400,
    fontSize: '14px',
    letterSpacing: '-0.05px',
    lineHeight: '20px',
  },
  subtitle1: {
    color: palette.text.primary,
    fontSize: '16px',
    letterSpacing: '-0.05px',
    lineHeight: '25px',
  },
  subtitle2: {
    color: palette.text.primary,
    fontSize: '14px',
    letterSpacing: 0,
    lineHeight: '16px',
  },
  body1: {
    color: palette.text.primary,
    fontSize: '14px',
    letterSpacing: '-0.05px',
    lineHeight: '1.5',
  },
  body2: {
    color: palette.text.primary,
    fontSize: '13px',
    letterSpacing: '-0.04px',
    lineHeight: '1.25',
  },
  button: {
    color: palette.text.primary,
    fontSize: '14px',
  },
  caption: {
    color: palette.text.secondary,
    fontSize: '12px',
    letterSpacing: '0.3px',
    lineHeight: '16px',
  },
})
export default typographyOptions

// alternative export using a global style sheet
// @TODO Maybe we should use https://mui.com/customization/unstyled-components/ and customise everything
export const GlobalTypographyStyle = () => (
  <Global
    styles={css`
      /* Override MuiDefault settings for the typography variants */
      .MuiTypography-root {
        &.MuiTypography-h1,
        &.MuiTypography-h2,
        &.MuiTypography-h3,
        &.MuiTypography-h4,
        &.MuiTypography-h5,
        &.MuiTypography-h6,
        &.MuiTypography-subtitle1,
        &.MuiTypography-subtitle2,
        &.MuiTypography-body1,
        &.MuiTypography-body2,
        &.MuiTypography-button,
        &.MuiTypography-caption {
          font-family: 'ars-maquette-web', sans-serif, Helvetica, Arial,
            'sans-serif';
        }
        &.MuiTypography-h1 {
          color: ${palette.text.primary};
          font-weight: 400;
          font-size: 35px;
          letter-spacing: -0.24px;
          line-height: 40px;
        }
        &.MuiTypography-h2 {
          color: ${palette.text.primary};
          font-weight: 400;
          font-size: 29px;
          letter-spacing: -0.24px;
          line-height: 32px;
        }
        &.MuiTypography-h3 {
          color: ${palette.text.primary};
          font-weight: 400;
          font-size: 24px;
          letter-spacing: -0.06px;
          line-height: 28px;
        }
        &.MuiTypography-h4 {
          font-weight: 400;
          font-size: 20px;
          letter-spacing: -0.06px;
          line-height: 24px;
          color: ${palette.text.primary};
        }
        &.MuiTypography-h5 {
          color: ${palette.text.primary};
          font-weight: 400;
          font-size: 16px;
          letter-spacing: -0.05px;
          line-height: 20px;
        }
        &.MuiTypography-h6 {
          color: ${palette.text.primary};
          font-weight: 400;
          font-size: 14px;
          letter-spacing: -0.05px;
          line-height: 20px;
        }
        &.MuiTypography-subtitle1 {
          color: ${palette.text.primary};
          font-weight: 400;
          letter-spacing: -0.05px;
          line-height: 25px;
        }
        &.MuiTypography-subtitle2 {
          color: ${palette.text.primary};
          font-weight: 400;
          letter-spacing: 0;
          line-height: 16px;
        }
        &.MuiTypography-body1 {
          color: ${palette.text.primary};
          font-size: 14px;
          letter-spacing: -0.05px;
          line-height: 21px;
        }
        &.MuiTypography-body2 {
          color: ${palette.text.primary};
          font-size: 12px;
          letter-spacing: -0.04px;
          line-height: 14px;
        }
        &.MuiTypography-button {
          color: ${palette.text.primary};
          font-size: 14px;
        }
        &.MuiTypography-caption {
          color: ${palette.text.secondary};
          font-size: 12px;
          letter-spacing: 0.3px;
          line-height: 16px;
        }
      }
    `}
  />
)
