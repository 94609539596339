// Material helpers

import { createTheme } from '@mui/material/styles'

import palette from './palette'
import typography from './typography'

const theme = createTheme({
  typography,
  palette,
  components: {
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        color: 'primary',
      },
    },
  },
  zIndex: {
    // `JointAppraisalFormItemDrawer` (and other drawer components) should be shown above the AskUs widget, which has a z-index of 10000.
    // @see https://v5-0-6.mui.com/customization/z-index/
    drawer: 1200 + 20000,
    modal: 1300 + 20000,
    snackbar: 1400 + 20000,
    tooltip: 1500 + 20000,
  },
})

export default theme
