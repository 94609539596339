import { AxiosError } from 'axios'

const loginPages: string[] = ['/login', '/signup', '/500', '/404']

const termsPages: string[] = [
  '/terms-and-conditions',
  '/privacy',
  '/website-terms-of-use',
]

const logoutPages: string[] = ['/logout', '/autologout']

// Logged in switch account is similar to multiple account
const switchAccountPages: string[] = ['/my-account/switch-account']

const secondStepPages = [
  '/login/mfa',
  '/login/multiple-account',
  '/login/departmental',
  '/login/admin',
]

const isTermsPath = (path: string): boolean =>
  termsPages.some((p) => path.startsWith(p))

export const isAnonymousPath = (path: string): boolean =>
  isTermsPath(path) || isLogoutPath(path)

export const isSwitchAccountPath = (path: string): boolean =>
  switchAccountPages.some((p) => path.startsWith(p))

export const isStartsWithLoginPath = (path: string): boolean =>
  loginPages.some((p) => path.startsWith(p))

export const isSignUpPath = (path: string): boolean =>
  !!path.match(/^\/.*sign-up/)

export const isSSOLoginPath = (path: string | undefined): boolean =>
  path != null && path.startsWith('/login/sso')

export const isLoginButNotSecondStepPath = (path: string): boolean =>
  loginPages.some((p) => path.startsWith(p)) && !isSecondStepLoginPath(path)

export const isSecondStepLoginPath = (path: string): boolean =>
  secondStepPages.some((p) => path.startsWith(p))

const isLogoutPath = (path: string): boolean =>
  logoutPages.some((p) => path.startsWith(p))

export const isMfaRequired = (err: AxiosError): boolean =>
  err.response?.status === 422 && /mfa/i.test(err.response.data?.message)

export const isMultiMemberSelectRequired = (err: AxiosError): boolean =>
  err.response?.status === 422 && /selected/i.test(err.response.data?.message)

export const isSelfServicePath = (path: string): boolean =>
  path.startsWith('/self-service')

/**
 * Gets a URL to redirect to.
 * @param path URL path to redirect to after next page.
 * @param queryUrl **Overrides `path`.**
 * @param nextPath Base URL path, eg. '/login'
 * @returns
 */
export function getNextUrl(
  path: string,
  queryUrl: string | undefined,
  nextPath: string
): string {
  let url
  let finalPath = nextPath

  // We want to return to the current path after going to nextPath
  // unless we're coming from the /login page
  // If we're on the SSO login page, we'll need to return to this page
  // after doing mfa/multiple-account selection
  // (and path will have params that ensure this happens correctly)
  if (!isStartsWithLoginPath(path) || isSSOLoginPath(path)) {
    url = path
  }

  // If the current path had a url query param, we need to add this to the next url
  if (queryUrl) {
    url = queryUrl
  }

  if (url) {
    // encode the url because it might itself have query params
    finalPath += `?url=${encodeURIComponent(url)}`
  }

  // console.log('getNextUrl', finalPath)

  return finalPath
}
