import { Fragment, useEffect, FunctionComponent } from 'react'
import { useForm } from 'react-hook-form'
import Head from 'next/head'
import * as FingerprintJS from '@fingerprintjs/fingerprintjs'
import * as Bowser from 'bowser'
import { ParsedUrlQuery } from 'querystring'
import { useRouter } from 'next/router'

import { NoAuthLayout } from '@/components/noAuthComponents/styled/NoAuthLayout'
import { post } from '@/utils/api'
import { redirect } from '@/utils/redirect'
import { focusField } from '@/utils/focusField'
import { styled, css } from '@/utils/styled'
import { BasicContainer } from '@/components/noAuthComponents/styled/BasicContainer'
import { FieldWrapper } from '@/components/noAuthComponents/styled/FieldWrapper'
import { CyanButton } from '@/components/noAuthComponents/styled/Button'
import { MarginTopP } from '@/components/noAuthComponents/styled/MarginTopP'
import { User } from '@/types/User'
import { MemberUser } from '@/types/MemberUser'
import { MFAActionPayload, AppNextPageContext } from '@/types/multiple'

const defaultRedirectUrl = '/'

export const validRedirectUrl = [
  '/business',
  '/calendar',
  '/manage-account',
  '/manage-integrations',
  '/my-account',
  '/people',
  '/self-service',
  '/xero-sign-up',
]

type LoginFnParams = {
  password: string
  email: string
  fingerprint: string
  os: string
  browser: string
  resolution: string
  timestamp: string
}

export type LoginProps = {
  title?: string
  subtitle?: string
  user?: MemberUser
  mfaAction?: MFAActionPayload
  submitValues?: LoginFnParams
  onSuccess?: (user: User) => void
}

export const sanitizeRedirectUrl = (query: ParsedUrlQuery) => {
  const { url } = query

  // console.log('url', url)

  return typeof url === 'string' &&
    validRedirectUrl.find((prefix) => url.startsWith(prefix))
    ? url
    : defaultRedirectUrl
}

export const Login = ({
  subtitle,
  title = 'Log in to MyHR',
  submitValues,
  onSuccess,
}: LoginProps): JSX.Element => {
  const nextUrl = sanitizeRedirectUrl(useRouter().query)

  // console.log('nextUrl', nextUrl)

  // NB: using the `autoFocus` attribute does not work for the login form on
  // pages for some reason
  useEffect(() => {
    return focusField('email')
  }, [])

  // Bind url to call to redirect.
  // Essentially the same as `(() => redirect({ nextUrl }))`.
  return (
    <LoginForm
      subtitle={subtitle}
      title={title}
      onSuccess={onSuccess || redirect.bind(null, { nextUrl })}
      submitValues={submitValues}
    />
  )
}

type LoginFormProps = LoginProps & {
  onSuccess: (user: User) => void
}

// `js` class is added to root (html) element.
export const JSOnlyForm = (props) => (
  <form
    css={css`
      transition: height 200ms, opacity 100ms;
      opacity: 0;
      display: none;

      html.js & {
        display: block;
        opacity: 1;
      }
    `}
    {...props}
    className="js-only"
  />
)

// `js` class is added to root (html) element.
export const ServerRenderedJSEnabledButFailedClientSide = styled('div')`
  transition: opacity 100ms;
  opacity: 1;

  html.js & {
    height: 0;
    opacity: 0;
    display: none;
  }
`

export const UnExpectedMessage = () => (
  <Fragment>
    <p>An unexpected error occurred. Apologies for any inconvenience.</p>
    <p>
      Try <b>force refreshing the page</b> (Ctrl-F5 or Cmd-Shift-R). If the
      problem continues contact us at{' '}
      <a href="mailto:help@myhr.works">help@myhr.works</a>.
    </p>
  </Fragment>
)

/**
 * Gets a formatted date in the browser's locale.
 *
 * Note: date-fns does not support timezones, so use standard Date functions.
 * "Specific non-location timezones are currently unavailable in date-fns,
 * so right now these tokens fall back to GMT timezones."
 *
 * Uses 'en-NZ' locale to force browser to specify alpha time zone.
 * (at least for NZ and Australia). Note: 'en-NZ' works for Australia as they
 * have the same date format as NZ and close enough timezone.
 *
 * If `undefined` or `client.getLanguage()` is used as the locale, and for
 * example the browser is set to 'en-GB', the timezone is GMT+offset.
 *
 * Some browsers may not support for Intl function `toLocaleString`, so
 * fall back to plain `toString`.
 *
 * @see https://date-fns.org/v2.30.0/docs/format
 */
const getFormattedDate = () => {
  try {
    // 10 May at 3:16 pm NZST
    return new Date().toLocaleString('en-NZ', {
      timeZoneName: 'short',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    })
  } catch (error) {
    // Wed May 10 2023 15:21:04 GMT+1200 (New Zealand Standard Time)
    return new Date().toString()
  }
}

const LoginForm: FunctionComponent<LoginFormProps> = ({
  title,
  onSuccess,
  subtitle,
  submitValues,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    setValue,
  } = useForm<LoginFnParams>()

  const submitLogin = async (values: LoginFnParams) => {
    try {
      // Get the time when the submit occurred.
      const { data: user } = await post<LoginFnParams, User>(
        { url: '/api/user/auth/login' },
        { ...values, timestamp: getFormattedDate() }
      )

      onSuccess(user)
    } catch (err) {
      const isLockedOut = err?.response?.data?.error === 'Locked out'
      if (isLockedOut) {
        setError('email', {
          message: `Locked out. Use "Forgotten password?" to unlock`,
        })
        clearErrors('password')
        return
      }
      const message = 'Login failed'
      setError('email', { message })
      setError('password', { message }, { shouldFocus: true })
    }
  }

  useEffect(() => {
    // Bowser was last updated in 2020.
    // See https://github.com/lancedikson/bowser
    const browser = Bowser.getParser(window.navigator.userAgent)

    // These values are informational (used for statistics), so it does not
    // matter overly if they are incorrect.
    setValue('os', browser.getOS().name ?? 'Unknown') // eg. 'Mac OS'
    setValue('browser', browser.getBrowserName()) // eg. 'Chrome'
    setValue('resolution', `${window.screen.width}x${window.screen.height}`)
    // eg. '2560x1440'

    // FingerprintJS attempts to access `window` during load so must do this
    // on client.
    // `false` -> don't send statistics to FingerprintJS servers.
    FingerprintJS.load({ monitoring: false })
      .then((fp) => fp.get())
      .then((client) => {
        setValue('fingerprint', client.visitorId)
        // eg. 'f0819c4465adce71e8a69f1cb3b2de50'
      })
      .catch((error) => {
        console.log('Could not get fingerprint', error.message)
      })
  }, [setValue])

  return (
    <Fragment>
      <Head>
        <title>{title}</title>
      </Head>
      <BasicContainer size="small">
        <h1>{title}</h1>
        {submitValues ? (
          <UnExpectedMessage />
        ) : (
          <Fragment>
            {!!subtitle && <p>{subtitle}</p>}
            <JSOnlyForm onSubmit={handleSubmit(submitLogin)} method="POST">
              <FieldWrapper name="email" error={errors.email}>
                <input
                  id="email-field"
                  type="email"
                  placeholder="Email"
                  aria-label="Email"
                  {...register('email', { required: true })}
                />
              </FieldWrapper>
              <FieldWrapper name="password" error={errors.password}>
                <input
                  id="password-field"
                  type="password"
                  placeholder="Password"
                  aria-label="Password"
                  {...register('password', { required: true })}
                />
              </FieldWrapper>
              <p>
                By signing into MyHR you agree to our{' '}
                <a href="https://knowledge.myhr.works/terms-and-conditions">
                  terms
                </a>
                .
              </p>
              <CyanButton data-testid="sign-in">Log In</CyanButton>
            </JSOnlyForm>
            <noscript>
              <p>Javascript must be enabled to login to MyHR</p>
            </noscript>
            <ServerRenderedJSEnabledButFailedClientSide>
              <UnExpectedMessage />
            </ServerRenderedJSEnabledButFailedClientSide>
            <MarginTopP>
              <a href="/forgotten-password">Forgotten password?</a>
              {process.env.NODE_ENV === 'development' && (
                <Fragment>
                  <br />
                  <a href="/signup">Don&apos;t have an account? Sign up now</a>
                </Fragment>
              )}
            </MarginTopP>
          </Fragment>
        )}
      </BasicContainer>
    </Fragment>
  )
}

export const LoginPage = (props: LoginProps): JSX.Element => (
  <NoAuthLayout>
    <Login {...props} />
  </NoAuthLayout>
)

LoginPage.getInitialProps = async (ctx: AppNextPageContext) => {
  const { pageProps } = ctx
  let body
  if (ctx.req?.method === 'POST') {
    body = (ctx.req as unknown as { body: unknown }).body
  }
  return {
    pageProps,
    submitValues: body,
  }
}
