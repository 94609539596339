import styled from '@/utils/styled'
import { basicTheme } from './basicTheme'

export const Button = styled('button')`
  cursor: pointer;
  border-radius: ${basicTheme.borderRadius};
  margin-top: 0.625rem;
  text-transform: uppercase;
  text-decoration: none !important;
  box-shadow: 0 2px 9px rgba(0, 0, 0, 0.25);
  display: inline-block;
  font: 13px ${basicTheme.fontFamily.body};
  font-weight: 500;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 2px solid transparent;
  padding: 0.675rem 1.875rem;
  line-height: 1.5;
  transition: color 0.35s ease-in-out, background-color 0.35s ease-in-out,
    background-position 0.35s ease-in-out, border-color 0.35s ease-in-out,
    box-shadow 0.35s ease-in-out;
`
export const CyanButton = styled(Button)`
  color: ${basicTheme.palette.black};
  background-color: ${basicTheme.palette.cyan};
  border-color: ${basicTheme.palette.cyan};

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem ${basicTheme.cyanBoxShadow};
  }
`

export const TextButton = styled(CyanButton)`
  background: transparent;
`

export const ButtonLink = Button.withComponent('a')
export const CyanButtonLink = CyanButton.withComponent('a')
