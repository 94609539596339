/**
 * Step 1. Login to Fontawesome (https://fontawesome.com) using the credentials in 1Password (user:pete@simnix.co.nz).
 * Step 2. Follow instructions to set up the NPM token (the tokens in the snippet will be our ones).
 * @see https://fontawesome.com/docs/web/setup/packages#set-up-npm-token-for-all-projects
 */
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome'
import {
  faChartLine,
  faGear,
  faLoader,
  faNewspaper as faNewspaperLight,
  faUser,
  faBell,
  faFile,
} from '@fortawesome/pro-light-svg-icons'
import {
  faSquareCheck,
  faCircleCheck,
  faCircleXmark,
  faSquareMinus,
  faCheck,
  faMinus,
  faCircle,
  faChevronDown,
  faCaretDown,
  faTriangleExclamation,
  faCircleInfo as faCircleInfoFilled,
  faBold,
} from '@fortawesome/free-solid-svg-icons'
import {
  faCalendar,
  faCalendarPlus,
  faChevronRight,
  faChevronLeft,
  faCircleArrowLeft,
  faClockRotateLeft,
  faCopy,
  faEllipsis,
  faEllipsisVertical,
  faEnvelope,
  faEye,
  faFileArrowUp,
  faFileLines,
  faLocationDot,
  faMessages,
  faNewspaper,
  faNoteSticky,
  faPen,
  faPlus,
  faSparkles,
  faSmile,
  faSquare,
  faTags,
  faTrashCan,
  faUserPlus,
  faUser as faUserRegular,
  faXmark,
  faCircleInfo,
  faEyeSlash,
  faCircleExclamation,
  faArrowLeft,
  faArrowRight,
  faHouse,
  faBriefcase,
  faUserGroup,
  faListCheck,
  faTrophy,
  faClock,
  faFolders,
  faFolderClosed,
  faChartLine as faChartLineRegular,
  faPuzzlePiece,
  faChevronsLeft,
  faChevronsRight,
  faBars,
  faCircleQuestion,
  faGear as faGearRegular,
  faBell as faBellRegular,
  faBellRing,
  faAlarmClock,
  faArrowUpRightFromSquare,
  faStar,
  faFilePen,
  faFileSignature,
  faUserTie,
  faPersonBurst,
  faClipboardUser,
  faPersonWalkingArrowRight,
  faItalic,
  faUnderline,
  faAlignLeft,
  faAlignCenter,
  faAlignRight,
  faAlignJustify,
  faUpload,
} from '@fortawesome/pro-regular-svg-icons'

import styled from '@/utils/styled'
import AccountCircle from '@mui/icons-material/AccountCircle'
import AddRounded from '@mui/icons-material/AddRounded'
import AddBox from '@mui/icons-material/AddBox'
import AddCircleRounded from '@mui/icons-material/AddCircleRounded'
import ArchiveRounded from '@mui/icons-material/ArchiveRounded'
import ArrowRightAltRounded from '@mui/icons-material/ArrowRightAltRounded'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined'
import ChatRounded from '@mui/icons-material/ChatRounded'
import ChatBubbleOutlineRounded from '@mui/icons-material/ChatBubbleOutlineRounded'
import CheckCircleOutlineRounded from '@mui/icons-material/CheckCircleOutlineRounded'
import CheckCircleRounded from '@mui/icons-material/CheckCircleRounded'
import CheckRounded from '@mui/icons-material/CheckRounded'
import CloseRounded from '@mui/icons-material/CloseRounded'
import CloudUploadRounded from '@mui/icons-material/CloudUploadRounded'
import ContentCopyRounded from '@mui/icons-material/ContentCopyRounded'
import DeleteRounded from '@mui/icons-material/DeleteRounded'
import DescriptionRounded from '@mui/icons-material/DescriptionRounded'
import DragIndicatorRounded from '@mui/icons-material/DragIndicatorRounded'
import EditRounded from '@mui/icons-material/EditRounded'
import ErrorOutlineRounded from '@mui/icons-material/ErrorOutlineRounded'
import ExpandMoreRounded from '@mui/icons-material/ExpandMoreRounded'
import FileUpload from '@mui/icons-material/FileUpload'
import FindInPageRounded from '@mui/icons-material/FindInPageRounded'
import GetAppRounded from '@mui/icons-material/GetAppRounded'
import HelpRounded from '@mui/icons-material/HelpRounded'
import InventoryRounded from '@mui/icons-material/InventoryRounded'
import CircleOutlined from '@mui/icons-material/CircleOutlined'
import LaunchRounded from '@mui/icons-material/LaunchRounded'
import List from '@mui/icons-material/List'
import MenuRounded from '@mui/icons-material/MenuRounded'
import MoreVertRounded from '@mui/icons-material/MoreVertRounded'
import NavigateBeforeRounded from '@mui/icons-material/NavigateBeforeRounded'
import NavigateNextRounded from '@mui/icons-material/NavigateNextRounded'
import NotificationsRounded from '@mui/icons-material/NotificationsRounded'
import PersonAdd from '@mui/icons-material/PersonAdd'
import People from '@mui/icons-material/People'
import PersonRounded from '@mui/icons-material/PersonRounded'
import PriorityHighRounded from '@mui/icons-material/PriorityHighRounded'
import RefreshRounded from '@mui/icons-material/RefreshRounded'
import RemoveCircleOutlineRounded from '@mui/icons-material/RemoveCircleOutlineRounded'
import Rule from '@mui/icons-material/Rule'
import SearchRounded from '@mui/icons-material/SearchRounded'
import VerifiedRounded from '@mui/icons-material/VerifiedRounded'
import WarningRounded from '@mui/icons-material/WarningRounded'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import PersonSearch from '@mui/icons-material/PersonSearch'
import Reviews from '@mui/icons-material/Reviews'
import PostAdd from '@mui/icons-material/PostAdd'
import { CalendarToday } from '@mui/icons-material'
import Star from '@mui/icons-material/Star'
import StarRounded from '@mui/icons-material/StarRounded'
import ShuffleIcon from '@mui/icons-material/Shuffle'
import Undo from '@mui/icons-material/Undo'
import ConnectWithoutContact from '@mui/icons-material/ConnectWithoutContact'
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons'
import { faFolderClosed as faFolderSolid } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faBullseyeArrow } from '@fortawesome/pro-regular-svg-icons'
import { Box, BoxProps, SxProps } from '@mui/material'

/**
 * NOTE
 * ----
 * When using an icon designed in Figma, use the IconContainer as this will account for padding
 * that is used by the icon set in Figma (@link src/components/elements/Icons.tsx Line 148)
 *
 * FontAwesome uses font size to draw the size of its icons.
 *
 * @see Outlined borders in {@link https://www.figma.com/file/cCXkNW4mreuKXdfkNjUSAy/Design-System?type=design&node-id=1097-1337&mode=dev FontAwesome smiley icons on Figma}.
 * @see {@link https://github.com/MyHR/member-forms/pull/2579#discussion_r1410092749 Explanation with images on GitHub}
 */
const createFontAwesomeIcon =
  // eslint-disable-next-line react/display-name
  (icon: IconProp) => (props?: Omit<FontAwesomeIconProps, 'icon'>) =>
    <FontAwesomeIcon {...props} icon={icon} />

/**
 * @see https://fonts.google.com/icons?icon.style=Rounded
 * @see https://material-ui.com/components/material-icons/
 */

export type IconSize = 12 | 16 | 20 | 24 | 28 | 32

type IconContainerProps = Omit<BoxProps, 'sx'> & {
  size: IconSize
  icon: JSX.Element | null
  sx?: SxProps
}

/**
 * MyHR Designed Icon Container that uses the predefined padding and font size of the icon set designed in Figma.
 *
 * @param size - Predefined size of the entire icon as shown in a figma design, inclusive of padding
 * @param icon - The icon to be displayed, use a fontawesome icon
 * @param sx - Additional sx props of the icon container
 * @param rest - Additional BoxProps for container
 * @returns Icon with predefined size and padding
 */
export const IconContainer = ({
  size,
  icon,
  sx,
  ...rest
}: IconContainerProps) => {
  const padding =
    size === 12
      ? '1px'
      : size === 16
      ? '2px'
      : size === 20
      ? '2px'
      : size === 24
      ? '2px'
      : size === 28
      ? '2px'
      : size === 32
      ? '3px'
      : '0px'

  const fontSize =
    size === 12
      ? '10px'
      : size === 16
      ? '13px'
      : size === 20
      ? '16px'
      : size === 24
      ? '20px'
      : size === 28
      ? '22px'
      : size === 32
      ? '26px'
      : '0px'

  const customSx = {
    padding,
    svg: {
      display: 'block',
      fontSize,
    },
    ...sx,
  }

  return (
    <Box sx={customSx} {...rest}>
      {icon}
    </Box>
  )
}

export const AccountCircleIcon = AccountCircle

export const AddIcon = AddRounded

export const AddBoxIcon = AddBox

export const AddCircleIcon = AddCircleRounded

export const AddPersonIcon = PersonAdd

export const AlarmClockIcon = createFontAwesomeIcon(faAlarmClock)

// export const AndroidIcon = AndroidRounded

// export const AppleIcon = Apple

export const ArchiveIcon = InventoryRounded

// @TODO Can't use this styled icon as a component prop
// export const ArchiveIcon = styled(Inventory2Rounded)`
//   transform: scale(0.9, 0.9);
//   transform-origin: center;
// `

export const ArrowDownIcon = styled(ArrowRightAltRounded)`
  transform: rotate(90deg);
`

export const ArrowUpIcon = styled(ArrowRightAltRounded)`
  transform: rotate(270deg);
`

export const ArrowLeftIcon = ArrowBackIcon

export const FAArrowLeftIcon = createFontAwesomeIcon(faArrowLeft)

export const FAArrowRightIcon = createFontAwesomeIcon(faArrowRight)

export const AnnouncementIcon = CampaignOutlinedIcon

export const CalendarIcon = CalendarToday

export const BlankCalendarIcon = createFontAwesomeIcon(faCalendar)
export const AddCalendarIcon = createFontAwesomeIcon(faCalendarPlus)

export const BullseyeArrow = createFontAwesomeIcon(faBullseyeArrow)

export const BriefcaseIcon = createFontAwesomeIcon(faBriefcase)

export const BarsIcon = createFontAwesomeIcon(faBars)

export const BellIcon = createFontAwesomeIcon(faBell)
export const BellRegularIcon = createFontAwesomeIcon(faBellRegular)
export const BellRingIcon = createFontAwesomeIcon(faBellRing)

export const BoldIcon = createFontAwesomeIcon(faBold)
export const ItalicIcon = createFontAwesomeIcon(faItalic)
export const UnderlineIcon = createFontAwesomeIcon(faUnderline)
export const LeftAlignIcon = createFontAwesomeIcon(faAlignLeft)
export const CenterAlignIcon = createFontAwesomeIcon(faAlignCenter)
export const RightAlignIcon = createFontAwesomeIcon(faAlignRight)
export const JustifyAlignIcon = createFontAwesomeIcon(faAlignJustify)

export const ChatIcon = styled(ChatRounded)`
  transform: scaleX(-1); // Flip horizontal
`

export const ChatBubble = ChatBubbleOutlineRounded

export const ChartLineIcon = createFontAwesomeIcon(faChartLine)

export const ChartLineRegularIcon = createFontAwesomeIcon(faChartLineRegular)

export const CaretDownIcon = createFontAwesomeIcon(faCaretDown)

export const CheckIcon = CheckRounded

export const FaCheckIcon = createFontAwesomeIcon(faCheck)

export const ChevronsLeftIcon = createFontAwesomeIcon(faChevronsLeft)

export const ChevronsRightIcon = createFontAwesomeIcon(faChevronsRight)

export const CircleCheckedIcon = CheckCircleOutlineRounded

export const CircleCheckedFilledIcon = CheckCircleRounded

export const CircleEmptyIcon = CircleOutlined

export const CircleErrorIcon = ErrorOutlineRounded

export const CircleInvalidIcon = RemoveCircleOutlineRounded

export const CirclePriorityIcon = ErrorOutlineRounded

export const CircleQuestionIcon = createFontAwesomeIcon(faCircleQuestion)

/**
 * "History" name is based on HistoryAction in performance review's OverviewCard,
 * which introduced this icon to the codebase.
 */
export const ClipboardUserIcon = createFontAwesomeIcon(faClipboardUser)

export const ClockRotateLeftIcon = createFontAwesomeIcon(faClockRotateLeft)

export const ClockIcon = createFontAwesomeIcon(faClock)

export const CloseIcon = CloseRounded

export const CollapseIcon = styled(ExpandMoreRounded)`
  transform: scaleY(-1); // Flip vertical
  transform-origin: center;
`

export const ConnectWithoutContactIcon = ConnectWithoutContact

export const CopyIcon = ContentCopyRounded

export const CopyIconFA = createFontAwesomeIcon(faCopy)

export const DeleteIcon = styled(DeleteRounded)`
  transform: scale(0.95, 0.95);
  transform-origin: center;
`

export const DownloadIcon = GetAppRounded

export const DownloadAllIcon = ArchiveRounded

export const DragIcon = DragIndicatorRounded

export const EditIcon = styled(EditRounded)`
  transform: scale(0.95, 0.95);
  transform-origin: center;
`

export const ExpandIcon = ExpandMoreRounded

export const ExternalLinkIcon = createFontAwesomeIcon(faArrowUpRightFromSquare)

export const FileLightIcon = createFontAwesomeIcon(faFile)

export const FileIcon = styled(DescriptionRounded)`
  transform: scale(0.95, 0.95);
  transform-origin: center;
`

export const FileUploadIcon = FileUpload
export const FAFileUploadIcon = createFontAwesomeIcon(faUpload)

export const GearIcon = createFontAwesomeIcon(faGear)

export const GearRegularIcon = createFontAwesomeIcon(faGearRegular)

export const EyeIcon = createFontAwesomeIcon(faEye)

export const EyeSlashIcon = createFontAwesomeIcon(faEyeSlash)

export const PenIcon = createFontAwesomeIcon(faPen)

export const TrashIcon = createFontAwesomeIcon(faTrashCan)

export const CircleArrowLeftIcon = createFontAwesomeIcon(faCircleArrowLeft)

export const CircleCheckIcon = createFontAwesomeIcon(faCircleCheck)

export const CircleExclamationIcon = createFontAwesomeIcon(faCircleExclamation)

export const CircleXmarkIcon = createFontAwesomeIcon(faCircleXmark)

export const Circle = createFontAwesomeIcon(faCircle)

export const FileArrowUpIcon = createFontAwesomeIcon(faFileArrowUp)

export const FilePenIcon = createFontAwesomeIcon(faFilePen)

export const FileSignatureIcon = createFontAwesomeIcon(faFileSignature)

// todo: These icons are JSX.Elements (not functions that return JSX.Element)  Convert to functions?

export const MarkIcon = <FontAwesomeIcon icon={faXmark} />

export const XMarkIcon = createFontAwesomeIcon(faXmark)

export const TagsIcon = <FontAwesomeIcon icon={faTags} />

export const NoteStickyIcon = createFontAwesomeIcon(faNoteSticky)

export const ChevronRightIcon = createFontAwesomeIcon(faChevronRight)

export const ChevronLeftIcon = createFontAwesomeIcon(faChevronLeft)

export const ChevronDownIcon = createFontAwesomeIcon(faChevronDown)

export const EmailIcon = createFontAwesomeIcon(faEnvelope)

export const FileLinesIcon = createFontAwesomeIcon(faFileLines)

export const FoldersIcon = createFontAwesomeIcon(faFolders)

export const FolderIcon = createFontAwesomeIcon(faFolderClosed)

export const FolderFilledIcon = createFontAwesomeIcon(faFolderSolid)

export const HelpIcon = HelpRounded

export const HouseIcon = createFontAwesomeIcon(faHouse)

export const InfoIcon = createFontAwesomeIcon(faCircleInfo)
export const CircleInfoFilledIcon = createFontAwesomeIcon(faCircleInfoFilled)

export const LaunchIcon = styled(LaunchRounded)`
  transform: scale(0.9, 0.9);
  transform-origin: center;
`

export const ListIcon = List

export const LinkExternalIcon = styled(LaunchRounded)`
  width: 16px;
  height: 16px;
  margin: ${(props) => props.theme.spacing(0, 0, 0, 0.5)};
  color: inherit;
`

export const LocationIcon = createFontAwesomeIcon(faLocationDot)

export const LoaderIcon = createFontAwesomeIcon(faLoader)

export const ListCheckIcon = createFontAwesomeIcon(faListCheck)

export const MenuIcon = MenuRounded

export const Messages = createFontAwesomeIcon(faMessages)

export const MoreIcon = createFontAwesomeIcon(faEllipsis)

export const MinusIcon = createFontAwesomeIcon(faMinus)

export const MoreVerticalIcon = createFontAwesomeIcon(faEllipsisVertical)

export const SquareMinusIcon = createFontAwesomeIcon(faSquareMinus)

export const OptionsIcon = MoreVertRounded

export const NavBackIcon = NavigateBeforeRounded

export const NavNextIcon = NavigateNextRounded

export const NewspaperLightIcon = createFontAwesomeIcon(faNewspaperLight)

export const NewspaperIcon = createFontAwesomeIcon(faNewspaper)

export const NotificationsIcon = NotificationsRounded

export const PersonIcon = PersonRounded

export const PersonIconFA = createFontAwesomeIcon(faUser)

export const PeopleIconFA = createFontAwesomeIcon(faUserGroup)

export const PersonIconRegularFA = createFontAwesomeIcon(faUserRegular)

export const PersonAddIcon = createFontAwesomeIcon(faUserPlus)

export const PersonBurstIcon = createFontAwesomeIcon(faPersonBurst)

export const PersonWalkingRightIcon = createFontAwesomeIcon(
  faPersonWalkingArrowRight
)

export const PlusIcon = createFontAwesomeIcon(faPlus)

export const PuzzleIcon = createFontAwesomeIcon(faPuzzlePiece)

export const PeopleIcon = People

export const PriorityIcon = PriorityHighRounded

export const QuestionIcon = createFontAwesomeIcon(faQuestionCircle)

export const RefreshIcon = RefreshRounded

export const ReviewIcon = Reviews

export const SearchIcon = SearchRounded

export const SearchFileIcon = FindInPageRounded

export const SearchPersonIcon = PersonSearch

export const ShuffleArrowIcon = ShuffleIcon

export const SmileIcon = createFontAwesomeIcon(faSmile)

export const SparklesIcon = createFontAwesomeIcon(faSparkles)

export const SquareCheckIcon = createFontAwesomeIcon(faSquareCheck)

export const SquareIcon = createFontAwesomeIcon(faSquare)

export const StarIcon = Star

export const FAStarIcon = createFontAwesomeIcon(faStar)

export const StarRoundedIcon = StarRounded

export const StartOnboardingIcon = PostAdd

export const SuccessIcon = CheckCircleRounded

export const TaskListIcon = Rule

export const TriangleExclamationIcon = createFontAwesomeIcon(
  faTriangleExclamation
)
export const TrophyIcon = createFontAwesomeIcon(faTrophy)

export const UndoIcon = Undo

export const UserTieIcon = createFontAwesomeIcon(faUserTie)

export const UploadIcon = CloudUploadRounded

export const VerifiedIcon = VerifiedRounded

export const WarningIcon = styled(WarningRounded)`
  transform: scale(1.1, 1.1);
  transform-origin: center;
`

export const VisbilityIcon = Visibility

export const VisibilityOffIcon = VisibilityOff
