import styled from '@/utils/styled'
import * as React from 'react'
import MuiModal from '@mui/material/Modal'
import { ModalProps } from '@mui/material/Modal'
import Paper from '@mui/material/Paper'
import { Button } from '@/components/form/Button'
import { useEffect, useState } from 'react'
import { SxProps } from '@mui/material'
import {
  ButtonProps,
  Button as ButtonV2,
} from '@/components/elements/figmaElements/Buttons/Button'

export const ModalWrap = styled(MuiModal)`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 8px;

  .MuiPaper-root {
    outline: none;
    max-height: 100vh;
    overflow: auto;
  }
`

export const ModalContainer = styled(Paper)`
  max-width: 95vw;
  padding: ${(props) => props.theme.spacing(3)};
  border: none;
  border-radius: 8px;
  box-shadow: 0 0 ${(props) => props.theme.spacing(2)} rgba(0, 0, 0, 0.5);

  @media (min-width: 600px) {
    max-width: 85vw;
  }

  @media (min-width: 1024px) {
    max-width: 800px;
  }

  &.modal-fixed-width {
    min-width: 95vw;

    @media (min-width: 600px) {
      min-width: 85vw;
    }

    @media (min-width: 1024px) {
      min-width: 800px;
    }
  }

  h2,
  h3 {
    margin: 0;
  }
`

export type OnClose = (
  evt: unknown,
  reason: 'backdropClick' | 'escapeKeyDown' | 'buttonClick'
) => void

interface ModalContextData {
  open: boolean
  setOpen: React.Dispatch<boolean>
  onClose?: OnClose
}

const ModalContext = React.createContext<ModalContextData>({
  open: false,
  // @TODO not sure if there's a better option for defining the initial value for the setOpen method
  setOpen: () => {
    return
  },
})

/**
 * A Mui ModalProps typs with optional open prop as it has a default
 */
type DefaultClosedModalProps = Omit<
  ModalProps,
  'open' | 'children' | 'onClose'
> & {
  open?: boolean
  setOpen?: React.Dispatch<boolean>
  children: React.ReactNode
  onClose?: OnClose
}

export const Modal = (props: DefaultClosedModalProps) => {
  const [open, setOpen] = React.useState(!!props.open)
  const value = React.useMemo(
    () => ({
      open: props.setOpen ? (props.open as boolean) : open,
      setOpen: props.setOpen ?? setOpen,
      onClose: props.onClose,
    }),
    [open, props.open, props.setOpen, props.onClose]
  )

  if (props.open === undefined && props.setOpen !== undefined) {
    throw new Error('Modal: setOpen prop must be used with open prop')
  }

  return (
    <ModalContext.Provider value={value}>
      {props.children}
    </ModalContext.Provider>
  )
}

export function useModalContext(): ModalContextData {
  const context = React.useContext(ModalContext)
  if (!context) {
    throw new Error(
      'Modal compound components cannot be rendered outside the Modal component'
    )
  }
  return context
}

type ModalChildrenProps = {
  children: React.ReactNode
}

function ModalLaunchButton({ children }) {
  return <ModalLaunch type="button">{children}</ModalLaunch>
}

function ModalLaunchLink({ children }) {
  return <ModalLaunch type="link">{children}</ModalLaunch>
}

export type ModalLaunchProps = Partial<
  Pick<ButtonProps, 'color' | 'variant' | 'size'>
> & {
  type?: 'button' | 'link' | 'div' | 'button-v2'
  /** If type is "link", then sometimes useful to pass custom `href`. */
  href?: HTMLAnchorElement['href']
  observeLinks?: boolean
  'aria-label'?: string
  launch?: (onClick: React.MouseEventHandler) => JSX.Element
  className?: string
  disabled?: boolean
} & ModalChildrenProps

function ModalLaunch({
  type,
  href,
  className,
  observeLinks,
  'aria-label': ariaLabel,
  launch,
  disabled,
  children,
  ...buttonProps
}: ModalLaunchProps): JSX.Element {
  const { setOpen } = useModalContext()

  const openModal = !disabled
    ? async (evt: React.MouseEvent<HTMLElement>) => {
        // Avoid opening modal if a link was clicked, for example, a link in an
        // onboarding task.

        const isLink =
          (evt.target as Element).tagName === 'A' ||
          (evt.target as Element).closest('a')

        if (!observeLinks || !isLink) {
          evt.preventDefault()
          setOpen(true)
        }
      }
    : undefined

  if (launch && openModal) {
    return launch(openModal)
  } else if (type === 'button') {
    return (
      <Button onClick={openModal} aria-label={ariaLabel} className={className}>
        {children}
      </Button>
    )
  } else if (type === 'button-v2') {
    return (
      <ButtonV2 onClick={openModal} aria-label={ariaLabel} {...buttonProps}>
        {children}
      </ButtonV2>
    )
  } else if (type === 'link') {
    return (
      <a
        href={href ?? '#modal'}
        onClick={openModal}
        aria-label={ariaLabel}
        className={className}
      >
        {children}
      </a>
    )
  } else if (type === 'div') {
    return (
      <div onClick={openModal} aria-label={ariaLabel} className={className}>
        {children}
      </div>
    )
  }

  return (
    <span onClick={openModal} aria-label={ariaLabel} className={className}>
      {children}
    </span>
  )
}

export const ModalContent: React.FC<{
  className?: string
  fixedWidth?: boolean
  backdropClose?: boolean
  onOpen?: () => void
  sx?: SxProps
}> = ({
  children,
  className,
  fixedWidth = false,
  backdropClose = true,
  onOpen,
  sx = [],
}): JSX.Element | null => {
  const { open, setOpen, onClose } = useModalContext()

  // didOpen allows call to onOpen to be made after render.
  const [didOpen, setDidOpen] = useState(false)

  useEffect(() => {
    if (onOpen && open && didOpen) {
      onOpen()
    }

    // Call onOpen on the next useEffect, thus RHF input refs are assigned.
    setDidOpen(open)
  }, [onOpen, open, didOpen])

  if (!open) {
    return null
  }

  const closeModal: ModalProps['onClose'] = (evt, reason) => {
    if (reason !== 'backdropClick' || backdropClose) {
      setOpen(false)
    }
    if (onClose) {
      onClose(evt, reason)
    }
  }

  if (fixedWidth) {
    className += ' modal-fixed-width'
  }

  return (
    <ModalWrap open={open} onClose={closeModal} className="ModalContent">
      <ModalContainer
        className={className}
        role="dialog"
        aria-label="Modal content"
        sx={sx}
      >
        {children}
      </ModalContainer>
    </ModalWrap>
  )
}

function ModalClose({ children }): JSX.Element {
  const { setOpen, onClose } = useModalContext()

  const closeModal = async (evt) => {
    evt.preventDefault()
    setOpen(false)
    if (onClose) {
      onClose(evt, 'buttonClick')
    }
  }

  return <div onClick={closeModal}>{children}</div>
}
function ModalCloseControlled({
  render,
}: {
  render: (close: () => void) => JSX.Element
}): JSX.Element {
  const { setOpen } = useModalContext()

  return render(() => setOpen(false))
}

Modal.LaunchButton = ModalLaunchButton
Modal.LaunchLink = ModalLaunchLink
// Use the generic "Launch" component if you cannot use a Button <button> or link <a>
Modal.Launch = ModalLaunch
Modal.Content = ModalContent
Modal.Close = ModalClose
/**
 * Use ModalCloseControlled when you need more control over when the
 * modal window is closed (by calling the close() function passed to the render prop)
 */
Modal.CloseControlled = ModalCloseControlled
